<template>
    <keep-alive>
        <div id="properties">
            <PageTitle :title="title" />
            <div v-if="!$store.getters.getLoaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div class="properties-big-container" v-else>
                <div class="property-type-container" v-for="(pType, i) in propertiesFilterByType" :key="i">
                    <div class="second-title">
                        <h2 data-aos="fade-up">
                            {{pType[0].Status | startCase | changeSold}}
                        </h2>
                    </div>
                    <div class="properties-container">
                        <div data-aos="fade-up" :data-aos-delay="$store.getters.isMobile ? 0 : (i%2)*400" class="property" v-for="(property, j) in pType" :key="j">
                            <router-link class="overlay-container" :to="`/property/${property.Slug}`">
                                <img :src="property.DisplayImage ? url+property.DisplayImage.url : '/images/placeholder.png'" :alt="property.Address" >
                                <span v-if="property.Status" class="tag">
                                    {{property.Status | startCase}}
                                </span>
                                <div class="overlay">
                                    <router-link class="btn" :to="`/property/${property.Slug}`">
                                        <span>View details</span><img class="arrow" src="/images/icon/arrow-white.svg" alt="Read More" />
                                    </router-link>
                                </div>
                            </router-link>
                            <h3>
                                {{property.Address1}} &nbsp;&nbsp; {{property.Price | priceFormat}} {{ property.Lease ? '/mo.' : '' }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </keep-alive>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Spinner from 'vue-simple-spinner'

export default {
    name: "Properties",
    components: {
        PageTitle,
        Spinner
    },
    data(){
        return{
            title: "LISTINGS",
            url: 'https://strapi.uptowncreative.io',
        }
    },
    filters: {
        changeSold: function (value) {
            if(value.toLowerCase() === 'sold'){
                return 'FEATURED SOLDS'
            }
        }
    },
    computed: {
        properties:function(){
            return this.$store.getters.properties;
        },
        propertiesFilterByType:function(){
            const properties = this.$store.getters.properties;
            let propertiesByType = [];
            for(let i = 0; i<properties.length; i++){
                let updated = false;
                for(let j = 0; j<propertiesByType.length; j++){
                    if(propertiesByType[j][0].Status === properties[i].Status){
                        propertiesByType[j].push(properties[i])
                        updated = true;
                    }
                }
                if(!updated){
                    const newArray = []
                    newArray.push(properties[i])
                    propertiesByType.push(newArray)
                }
            }
            return propertiesByType;
        }
    },
    metaInfo: {
        title: 'LISTINGS',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description', 
            content: "WALTERS | PLAXEN"
        }]
    },
    mounted:function(){
        if(!this.$store.getters.loaded || this.$store.getters.checkExpiryDate){
            this.$store.dispatch('updateProperties');
        }
    },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.second-title{
    margin: 100px 20px;
    text-align: center;
    text-transform: uppercase;
}
.properties-big-container{
    margin: 100px 0;
}
.properties-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.property{
    text-align: center;
    height: 60vh;
    .overlay-container{
        height: 80%;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .tag{
        position: absolute;
        bottom: 20px;
        right: 50%;
        transform: translateX(50%);
    }
    h3{
        text-transform: uppercase;
    }
}
.overlay .btn span{
    white-space: nowrap;
}

@media (max-width: $tablet) {
    .property{
        height: 400px;
    }
}

@media (max-width: $mobile) {
    .properties-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
        margin: 60px 0;
    }
    .property{
        height: 300px;
    }
}

</style>
